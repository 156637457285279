import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import FacebookIcon from '../Icon/facebook.png';
import InstagramIcon from '../Icon/instagram.png';
import YouTubeIcon from '../Icon/Youtube-Icon.png';
import TwitterIcon from '../Icon/X.png';
import LinkedInIcon from '../Icon/LinkedIn.png';
import Footer1 from '../Icon/Connect-Background.png';

function Footer() {
    return (
        <Box
            sx={{
                backgroundColor: '#1A1A2E',
                color: '#fff',
                padding: '40px 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 'auto',
            }}
        >
            <Grid container spacing={4} justifyContent="center">
                {/* Quick Links Section 1 */}
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Quick Links
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Link to="/about-us" style={{ color: '#fff', textDecoration: 'none', margin: '5px 0' }}>
                            About Us
                        </Link>
                        {/* <Link to="/contact-us" style={{ color: '#fff', textDecoration: 'none', margin: '5px 0' }}>
                            Contact Us
                        </Link> */}
                        <Link to="/faqs" style={{ color: '#fff', textDecoration: 'none', margin: '5px 0' }}>
                            FAQs
                        </Link>
                    </Box>
                </Grid>

                {/* Quick Links Section 2 */}
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Legal
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                        <a href="/Terms_of_Service.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Terms of Service
                        </a>
                        <a href="/Cookies_policy.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Cookies Policy
                        </a>
                        <a href="/Privacy_policy.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Privacy Policy
                        </a>
                    </Box>
                </Grid>

                {/* Contact Us Section */}
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                        Email: <a href="mailto:abc@gmail.com" style={{ color: '#fff', textDecoration: 'none' }}>official@anthrasync.com</a>
                    </Typography>
                </Grid>

                {/* Social Media Section */}
                <Grid item xs={12} md={3} textAlign="center">
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Follow Us
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton href="https://www.facebook.com/people/Anthrasync/61566752842183/?mibextid=qi2Omg&rdid=7fox9Ezc0map1mup&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FJxLq9NGoLNKzuhmK%2F%3Fmibextid%3Dqi2Omg" target="_blank" rel="noopener noreferrer">
                            <img src={FacebookIcon} alt="Facebook" style={{ width: '30px', height: '30px', margin: '0 10px' }} />
                        </IconButton>
                        <IconButton href="https://www.instagram.com/anthrasync/" target="_blank" rel="noopener noreferrer">
                            <img src={InstagramIcon} alt="Instagram" style={{ width: '30px', height: '30px', margin: '0 10px' }} />
                        </IconButton>
                        <IconButton href="https://www.youtube.com/@AnthrasyncMarketing " target="_blank" rel="noopener noreferrer">
                            <img src={YouTubeIcon} alt="YouTube" style={{ width: '30px', height: '30px', margin: '0 10px' }} />
                        </IconButton>
                        <IconButton href="https://x.com/anthrasync" target="_blank" rel="noopener noreferrer">
                            <img src={TwitterIcon} alt="X" style={{ width: '30px', height: '30px', margin: '0 10px' }} />
                        </IconButton>
                        <IconButton href="https://www.linkedin.com/company/anthrasync-solutions-private-limited/" target="_blank" rel="noopener noreferrer">
                            <img src={LinkedInIcon} alt="LinkedIn" style={{ width: '30px', height: '30px', margin: '0 10px' }} />
                        </IconButton>
                    </Box>
                </Grid>

                {/* Copyright Section */}
                <Grid item xs={12} textAlign="center">
                    <Typography variant="body2" sx={{ fontSize: '1.2rem' }}>
                        © 2024 Anthrasync Inc. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;
