import React from 'react';
import { Link } from 'react-router-dom';
import DemoImage from '../Icon/Background2.png';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import { Typography } from '@mui/material';
import './ComingSoon.css'; // Import the CSS for styling

function ComingSoon() {
  const firstWord = "COMING";
  const secondWord = "SOON!";
  return (
    <div style={{
      // backgroundImage: `url(${DemoImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      color: 'white',
    }}>
      <Link to="/home" style={{ position: 'absolute', top: '20px' }}>
        <img 
          src={Logo} 
          alt="Logo" 
          style={{ 
            width: '250px', // Adjust logo size as needed
            height: 'auto',
          }} 
        />
      </Link>
      <div className="coming-soon-container">
      {firstWord.split("").map((char, index) => (
        <Typography 
          key={index} 
          variant="h3" 
          className="letter" 
          style={{ animationDelay: `${index * 0.3}s` }}
        >
          {char}
        </Typography>
      ))}
      <span className="space"> {/* Add a space here */}
        <Typography 
          variant="h3" 
          className="letter" 
          style={{ animationDelay: `${firstWord.length * 0.3}s` }} // Delay after the first word
        >
          &nbsp; {/* Non-breaking space for consistent spacing */}
        </Typography>
      </span>
      {secondWord.split("").map((char, index) => (
        <Typography 
          key={index} 
          variant="h3" 
          className="letter" 
          style={{ animationDelay: `${(firstWord.length + index + 1) * 0.3}s` }} // Adjust delay for the second word
        >
          {char}
        </Typography>
      ))}
    </div>
    </div>
  );
}

export default ComingSoon;
