import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../Header/Header';
import Footer from './Footer';
import axios from 'axios';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const faqs = [
  {
    question: "What is Anthrasync?",
    answer: "Anthrasync is a knowledge exchange platform where people can share and learn knowledge irrespective of their locations and languages.",
  },
  {
    question: "Why Anthrasync?",
    answer: "Free flow of knowledge is crucial for growth and development of everyone. Anthrasync platform plays an important role in ensuring the same.",
  },
  {
    question: "Who can use the platform?",
    answer: "Currently the platform has been built for Indian users; in the future, the platform will be available globally.",
  },
  {
    question: "Who can benefit?",
    answer: "People who are eager to learn and passionate about teaching.",
  },
  {
    question: "Can users earn money from this platform?",
    answer: "Yes, every shared information counts.",
  },
  {
    question: "Can users teach and learn in any language?",
    answer: "Yes, the platform provides and supports multiple languages.",
  },
];

function Faqs() {
  const [userQuestion, setUserQuestion] = useState('');

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://88.222.212.226:8000/create_asked_questions/', {
        question: userQuestion,
      });
      alert('Your question has been submitted successfully!');
      setUserQuestion(''); // Clear the input field after submission
    } catch (error) {
      console.error("Error submitting the question:", error);
      alert("There was an error submitting your question. Please try again.");
    }
  };

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Box
        sx={{
          maxWidth: 800,
          margin: 'auto',
          padding: { xs: 2, sm: 3, md: 4, lg: 5 }, // Responsive padding for different screen sizes
          marginTop: { xs: 4, sm: 5, md: 5, lg: 6 }, // Responsive marginTop
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ fontFamily: 'Helvetica, sans-serif' }}
        >
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 2,
              backgroundColor: '#1C1C1C', // Set background color to Charcoal Black
              transition: 'border-color 0.3s',
              border: '2px solid transparent', // Default border
              '&:hover': {
                borderColor: 'blue', // Change border color on hover
              },
            }}
          >
            <Accordion sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ backgroundColor: '#1C1C1C', color: 'white' }} // Set background color for the summary
              >
                <Typography variant="h6" sx={{ fontFamily: 'Times New Roman, serif' }}>{faq.question}</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails sx={{ backgroundColor: '#1C1C1C', color: 'white' }}> {/* Set background color for details */}
                <Typography variant="body1" sx={{ fontFamily: 'Calibri, sans-serif', color: 'textSecondary' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
      {/* <Box
        sx={{
          width: { xs: '80%', sm: '85%' }, // Set width for small screens and 100% for larger screens
          maxWidth: 800,
          margin: 'auto',
          padding: { xs: 2, sm: 4 }, // Responsive padding
          backgroundColor: '#1C1C1C',
          borderRadius: 2,
          marginTop: { xs: 2, sm: 4 },
          color: 'white',
          transition: 'border-color 0.3s',
          border: '2px solid transparent', // Default border
          '&:hover': {
            borderColor: 'blue', // Change border color on hover
          },
          mb: 10,
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Times New Roman, serif' }}>
          Ask Us Your Question
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Your Question"
            variant="outlined"
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: { xs: '100%', sm: 'auto' } }} // Button takes full width on small screens
          >
            Submit
          </Button>
        </form>
      </Box> */}

      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer />
    </div>
  );
}

export default Faqs;
