import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, Avatar, TextField, Button, Input, MenuItem, Select, InputLabel, FormControl, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { Edit, Save, AccountBalance, School, FileUpload, Email, Phone, Home, CalendarToday, LocationOn, Person, Lock, Delete, CheckCircle } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';

// Create theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1111',
    },
    secondary: {
      main: '#1111',
    },
    background: {
      paper: '#1111',
    },
  },
});



// Styled Components
const ProfileHeader = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #55555, #77777)',
  color: 'white',
  padding: theme.spacing(4),
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  color:'white',
  padding: theme.spacing(4),
  backgroundColor: '#5555',
  marginBottom: theme.spacing(3),
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary.main}`,
  marginRight: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1rem',
  marginBottom: theme.spacing(1),
}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#7777',
    borderRadius: '10px',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#7777',
  color: 'white',
  padding: theme.spacing(1, 4),
  borderRadius: '25px',
  '&:hover': {
    backgroundColor: '#1E90FF',
  },
  marginTop: theme.spacing(2),
}));


// User Profile Component
function UserProfile() {

  const [language, setLanguage] = useState('en');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    setSnackbarMessage(`Language changed to: ${e.target.value}`);
    setOpenSnackbar(true);
  };

  const handlePasswordChange = () => {
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("Passwords don't match!");
      setOpenSnackbar(true);
      return;
    }
    // Here you would handle the password change (API call)
    setSnackbarMessage('Password changed successfully!');
    setOpenSnackbar(true);
  };

  const handleDeleteAccount = () => {
    // Add confirmation logic or API call to delete the account
    setSnackbarMessage('Account deleted successfully!');
    setOpenSnackbar(true);
    setOpenDeleteDialog(false);
  };

  const [verificationDetails, setVerificationDetails] = useState({
    email: '',
    contactNo: '',
    aadhar: '',
    pan: '',
    location: '',
  });

  const [feedback, setFeedback] = useState({
    email: '',
    contactNo: '',
    aadhar: '',
    pan: '',
    location: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar for feedback message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVerificationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleVerifyField = (fieldName) => {
    let feedbackMessage = '';
    let isValid = false;

    switch (fieldName) {
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValid = emailRegex.test(verificationDetails.email);
        feedbackMessage = isValid
          ? 'Email is valid!'
          : 'Invalid email format. Please enter a valid email address.';
        break;
      case 'contactNo':
        const contactRegex = /^[0-9]{10}$/;
        isValid = contactRegex.test(verificationDetails.contactNo);
        feedbackMessage = isValid
          ? 'Contact number is valid!'
          : 'Invalid contact number. It should be 10 digits.';
        break;
      case 'aadhar':
        const aadharRegex = /^[2-9]{1}[0-9]{3}\s{0,1}[0-9]{4}\s{0,1}[0-9]{4}$/;
        isValid = aadharRegex.test(verificationDetails.aadhar);
        feedbackMessage = isValid
          ? 'Aadhar number is valid!'
          : 'Invalid Aadhar number. Please check the format.';
        break;
      case 'pan':
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        isValid = panRegex.test(verificationDetails.pan);
        feedbackMessage = isValid
          ? 'PAN number is valid!'
          : 'Invalid PAN number. Please check the format.';
        break;
      case 'location':
        isValid = verificationDetails.location.trim() !== '';
        feedbackMessage = isValid
          ? 'Location is valid!'
          : 'Location cannot be empty. Please enter your location.';
        break;
      default:
        feedbackMessage = 'Invalid input!';
    }

    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [fieldName]: feedbackMessage,
    }));

    setOpenSnackbar(true);
  };

  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubSection, setSelectedSubSection] = useState('');

  const sections = [
    { name: 'Personal Information', subSections: ['Name', 'Date of Birth', 'Contact Info'] },
    { name: 'Educational Details', subSections: ['Class Name', 'Certificates'] },
    { name: 'Bank Details', subSections: ['Bank Name', 'Account Number', 'IFSC Code'] },
  ];



  const handleSectionChange = (e) => {
    const section = e.target.value;
    setSelectedSection(section);
    setSelectedSubSection('');
  };

  const handleSubSectionChange = (e) => {
    setSelectedSubSection(e.target.value);
  };

  const handleApplyForAccess = () => {
    alert(`Applied for access to ${selectedSubSection} in ${selectedSection}`);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [userDetails, setUserDetails] = useState({
    photo: 'https://via.placeholder.com/150',
    name: 'John Doe',
    email: 'john.doe@example.com',
    contact: '+123 456 7890',
    designation: 'Software Engineer',
    dob: '1990-01-01',
    city: 'Springfield',
    state: 'Illinois',
    country: 'USA',
    pincode: '62701',
    aboutMe: 'I am a passionate software engineer with a love for coding and problem-solving.',
    bankName: 'ABC Bank',
    accountNumber: '1234567890',
    ifscCode: 'ABC123456',
    educationalDetails: [
      { className: 'B.Sc. Computer Science', certificate: null },
    ],
    professionalDetails: [
      { jobRole: 'Software Engineer', experienceCertificate: null },
    ],
    achievements: [
      { name: 'Best Innovator Award', certificate: null },
    ],
  });

  const [verificationStatus, setVerificationStatus] = useState({
    email: false,
    mobile: false,
    location: false,
    digilocker: false,
  });

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  const handleEducationChange = (index, event) => {
    const newEducationalDetails = [...userDetails.educationalDetails];
    newEducationalDetails[index][event.target.name] = event.target.value;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      educationalDetails: newEducationalDetails,
    }));
  };



  const handleAddEducation = () => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      educationalDetails: [
        ...prevDetails.educationalDetails,
        { className: '', certificate: null },
      ],
    }));
  };

  const handleDeleteEducation = (index) => {
    const updatedEducation = userDetails.educationalDetails.filter((_, idx) => idx !== index);
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      educationalDetails: updatedEducation,
    }));
  };

  const handleVerifySection = (section) => {
    setVerificationStatus((prevState) => ({
      ...prevState,
      [section]: true,
    }));
  };

  // Handle adding new details for education, professional, and achievements
  const handleAddDetails = (section) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [section]: [...prevDetails[section], { jobRole: '', experienceCertificate: null }],
    }));
  };

  // Handle removing specific details
  const handleDeleteDetails = (section, index) => {
    const updatedDetails = userDetails[section].filter((_, idx) => idx !== index);
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [section]: updatedDetails,
    }));
  };

  // Handle changes for professional and achievement details
  const handleDetailsChange = (section, index, event) => {
    const { name, value } = event.target;
    const newDetails = [...userDetails[section]];
    newDetails[index][name] = value;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [section]: newDetails,
    }));
  };

  // Handle file upload for professional and achievements certificates
  const handleFileUpload = (section, index, event) => {
    const newDetails = [...userDetails[section]];
    newDetails[index].certificate = event.target.files[0];
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [section]: newDetails,
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Header1 />
    
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ marginTop: 10, mb:10 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            {/* Profile Header */}
            <ProfileHeader>
              <ProfileAvatar src={userDetails.photo} alt="User Photo" />
              <Box >
                <Typography variant="h4" fontWeight="bold">
                  {userDetails.name}
                </Typography>
                <Typography variant="body1">{userDetails.designation}</Typography>
              </Box>
            </ProfileHeader>

            {/* About Me Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>About Me</SectionTitle>
                <Typography variant="body1">{userDetails.aboutMe}</Typography>
                {isEditing && (
                  <InputField
                    name="aboutMe"
                    value={userDetails.aboutMe}
                    onChange={handleChange}
                    label="About Me"
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                )}
              </CardContent>
            </ProfileCard>

            {/* Personal Information Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Personal Info</SectionTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <Person /> Name: {userDetails.name}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="name"
                        value={userDetails.name}
                        onChange={handleChange}
                        label="Full Name"
                        variant="outlined"
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <CalendarToday /> Date of Birth: {userDetails.dob}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="dob"
                        value={userDetails.dob}
                        onChange={handleChange}
                        label="Date of Birth"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </ProfileCard>

            {/* Contact Information Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Contact Info</SectionTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <Email /> Email: {userDetails.email}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="email"
                        value={userDetails.email}
                        onChange={handleChange}
                        label="Email"
                        variant="outlined"
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <Phone /> Contact: {userDetails.contact}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="contact"
                        value={userDetails.contact}
                        onChange={handleChange}
                        label="Phone Number"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </ProfileCard>

            {/* Address Information Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Address Info</SectionTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <Home /> City: {userDetails.city}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="city"
                        value={userDetails.city}
                        onChange={handleChange}
                        label="City"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <Home /> State: {userDetails.state}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="state"
                        value={userDetails.state}
                        onChange={handleChange}
                        label="State"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <LocationOn /> Country: {userDetails.country}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="country"
                        value={userDetails.country}
                        onChange={handleChange}
                        label="Country"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <LocationOn /> Pincode: {userDetails.pincode}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="pincode"
                        value={userDetails.pincode}
                        onChange={handleChange}
                        label="Pincode"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </ProfileCard>



            {/* Bank Account Information Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Bank Account Info</SectionTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <AccountBalance /> Bank Name: {userDetails.bankName}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="bankName"
                        value={userDetails.bankName}
                        onChange={handleChange}
                        label="Bank Name"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <AccountBalance /> Account Number: {userDetails.accountNumber}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="accountNumber"
                        value={userDetails.accountNumber}
                        onChange={handleChange}
                        label="Account Number"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <AccountBalance /> IFSC Code: {userDetails.ifscCode}
                    </Typography>
                    {isEditing && (
                      <InputField
                        name="ifscCode"
                        value={userDetails.ifscCode}
                        onChange={handleChange}
                        label="IFSC Code"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </ProfileCard>

            

            {/* Educational Details Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Educational Details</SectionTitle>
                {userDetails.educationalDetails.map((education, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Class Name: {education.className}</Typography>
                        {isEditing && (
                          <InputField
                            name="className"
                            value={education.className}
                            onChange={(e) => handleDetailsChange('educationalDetails', index, e)}
                            label="Class Name"
                            variant="outlined"
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                          Certificate: {education.certificate ? education.certificate.name : 'No file selected'}
                        </Typography>
                        {isEditing && (
                          <Input
                            accept="application/pdf, image/*"
                            id={`upload-certificate-${index}`}
                            type="file"
                            onChange={(e) => handleFileUpload('educationalDetails', index, e)}
                            style={{ display: 'none' }}
                          />
                        )}
                        {isEditing && (
                          <label htmlFor={`upload-certificate-${index}`}>
                            <Button
                              component="span"
                              variant="contained"
                              color="secondary"
                              startIcon={<FileUpload />}
                            >
                              Upload Certificate
                            </Button>
                          </label>
                        )}
                      </Grid>
                    </Grid>
                    {isEditing && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteDetails('educationalDetails', index)}
                        startIcon={<Delete />}
                      >
                        Delete Education
                      </Button>
                    )}
                  </Box>
                ))}
                {isEditing && (
                  <ActionButton onClick={() => handleAddDetails('educationalDetails')} startIcon={<School />}>
                    Add More Education
                  </ActionButton>
                )}
              </CardContent>
            </ProfileCard>

            {/* Professional Details Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Professional Details</SectionTitle>
                {userDetails.professionalDetails.map((professional, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Job Role: {professional.jobRole}</Typography>
                        {isEditing && (
                          <InputField
                            name="jobRole"
                            value={professional.jobRole}
                            onChange={(e) => handleDetailsChange('professionalDetails', index, e)}
                            label="Job Role"
                            variant="outlined"
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                          Experience Certificate: {professional.experienceCertificate ? professional.experienceCertificate.name : 'No file selected'}
                        </Typography>
                        {isEditing && (
                          <Input
                            accept="application/pdf, image/*"
                            id={`upload-experience-certificate-${index}`}
                            type="file"
                            onChange={(e) => handleFileUpload('professionalDetails', index, e)}
                            style={{ display: 'none' }}
                          />
                        )}
                        {isEditing && (
                          <label htmlFor={`upload-experience-certificate-${index}`}>
                            <Button
                              component="span"
                              variant="contained"
                              color="secondary"
                              startIcon={<FileUpload />}
                            >
                              Upload Certificate
                            </Button>
                          </label>
                        )}
                      </Grid>
                    </Grid>
                    {isEditing && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteDetails('professionalDetails', index)}
                        startIcon={<Delete />}
                      >
                        Delete Professional Detail
                      </Button>
                    )}
                  </Box>
                ))}
                {isEditing && (
                  <ActionButton onClick={() => handleAddDetails('professionalDetails')} startIcon={<Person />}>
                    Add More Professional Details
                  </ActionButton>
                )}
              </CardContent>
            </ProfileCard>

            {/* Achievements Card */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Achievements</SectionTitle>
                {userDetails.achievements.map((achievement, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Achievement Name: {achievement.name}</Typography>
                        {isEditing && (
                          <InputField
                            name="name"
                            value={achievement.name}
                            onChange={(e) => handleDetailsChange('achievements', index, e)}
                            label="Achievement Name"
                            variant="outlined"
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                          Certificate: {achievement.certificate ? achievement.certificate.name : 'No file selected'}
                        </Typography>
                        {isEditing && (
                          <Input
                            accept="application/pdf, image/*"
                            id={`upload-achievement-certificate-${index}`}
                            type="file"
                            onChange={(e) => handleFileUpload('achievements', index, e)}
                            style={{ display: 'none' }}
                          />
                        )}
                        {isEditing && (
                          <label htmlFor={`upload-achievement-certificate-${index}`}>
                            <Button
                              component="span"
                              variant="contained"
                              color="secondary"
                              startIcon={<FileUpload />}
                            >
                              Upload Certificate
                            </Button>
                          </label>
                        )}
                      </Grid>
                    </Grid>
                    {isEditing && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteDetails('achievements', index)}
                        startIcon={<Delete />}
                      >
                        Delete Achievement
                      </Button>
                    )}
                  </Box>
                ))}
                {isEditing && (
                  <ActionButton onClick={() => handleAddDetails('achievements')} startIcon={<CalendarToday />}>
                    Add More Achievements
                  </ActionButton>
                )}
              </CardContent>
            </ProfileCard>


            {/* Request Session Access Section */}
            <ProfileCard>
              <CardContent>
                <SectionTitle>Request Access</SectionTitle>

                {/* Section Dropdown */}
                <FormControl fullWidth>
                  <InputLabel>Choose Section</InputLabel>
                  <Select
                    value={selectedSection}
                    onChange={handleSectionChange}
                    label="Choose Section"
                  >
                    {sections.map((section, index) => (
                      <MenuItem key={index} value={section.name}>
                        {section.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Sub-Section Dropdown */}
                {selectedSection && (
                  <FormControl fullWidth sx={{ marginTop: 2 , color:'white'}}>
                    <InputLabel>Choose Sub-Section</InputLabel>
                    <Select
                      value={selectedSubSection}
                      onChange={handleSubSectionChange}
                      label="Choose Sub-Section"
                    >
                      {sections
                        .find((section) => section.name === selectedSection)
                        ?.subSections.map((subSection, index) => (
                          <MenuItem key={index} value={subSection}>
                            {subSection}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {/* Apply for Access Button */}
                <ActionButton
                  sx={{ marginTop: 3, color:'white' }}
                  onClick={handleApplyForAccess}
                  disabled={!selectedSubSection}
                >
                  Apply for Access
                </ActionButton>
              </CardContent>
            </ProfileCard>

            <Container maxWidth="lg" sx={{ marginTop: 5 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={10} md={10} lg={25}> {/* Adjusted width here */}
                  {/* Verification Details Card */}
                  <ProfileCard>
                    <CardContent>
                      <SectionTitle>Verification Details</SectionTitle>
                      <Grid container spacing={10}>
                        {/* Email Input with Verification Button */}
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name="email"
                            value={verificationDetails.email}
                            onChange={handleChange}
                            label="Email ID"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <Email sx={{ color: '#00796b' }} />,
                            }}
                          />
                          <ActionButton onClick={() => handleVerifyField('email')} startIcon={<CheckCircle />}>
                            Verify Email
                          </ActionButton>
                        </Grid>

                        {/* Contact Number Input with Verification Button */}
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name="contactNo"
                            value={verificationDetails.contactNo}
                            onChange={handleChange}
                            label="Contact Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <Phone sx={{ color: '#00796b' }} />,
                            }}
                          />
                          <ActionButton onClick={() => handleVerifyField('contactNo')} startIcon={<CheckCircle />}>
                            Verify Contact
                          </ActionButton>
                        </Grid>

                        {/* Aadhar Input with Verification Button */}
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name="aadhar"
                            value={verificationDetails.aadhar}
                            onChange={handleChange}
                            label="Aadhar Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <Lock sx={{ color: '#00796b' }} />,
                            }}
                          />
                          <ActionButton onClick={() => handleVerifyField('aadhar')} startIcon={<CheckCircle />}>
                            Verify Aadhar
                          </ActionButton>
                        </Grid>

                        {/* PAN Input with Verification Button */}
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name="pan"
                            value={verificationDetails.pan}
                            onChange={handleChange}
                            label="PAN Number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <Lock sx={{ color: '#00796b' }} />,
                            }}
                          />
                          <ActionButton onClick={() => handleVerifyField('pan')} startIcon={<CheckCircle />}>
                            Verify PAN
                          </ActionButton>
                        </Grid>

                        {/* Location Input with Verification Button */}
                        <Grid item xs={12}>
                          <InputField
                            name="location"
                            value={verificationDetails.location}
                            onChange={handleChange}
                            label="Location"
                            variant="outlined"
                            InputProps={{
                              startAdornment: <LocationOn sx={{ color: '#00796b' }} />,
                            }}
                          />
                          <ActionButton onClick={() => handleVerifyField('location')} startIcon={<CheckCircle />}>
                            Verify Location
                          </ActionButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </ProfileCard>

                  {/* Snackbar for feedback */}
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={4000}
                    onClose={() => setOpenSnackbar(false)}
                  >
                    <Alert
                      onClose={() => setOpenSnackbar(false)}
                      severity={feedback.email || feedback.contactNo || feedback.aadhar || feedback.pan || feedback.location ? 'success' : 'error'}
                      sx={{ width: '100%' }}
                    >
                      {feedback.email || feedback.contactNo || feedback.aadhar || feedback.pan || feedback.location}
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth="lg" sx={{ marginTop: 5 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={10} md={25}>
                  {/* Language Selection Card */}
                  <ProfileCard>
                    <CardContent>
                      <SectionTitle>Language Selection</SectionTitle>
                      <FormControl fullWidth>
                        <InputLabel>Choose Language</InputLabel>
                        <Select
                          value={language}
                          onChange={handleLanguageChange}
                          label="Choose Language"
                          sx={{ backgroundColor: '#f5f5f5' }}
                        >
                          <MenuItem value="en">English</MenuItem>
                          <MenuItem value="es">Spanish</MenuItem>
                          <MenuItem value="fr">French</MenuItem>
                          <MenuItem value="de">German</MenuItem>
                          {/* Add more languages as needed */}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </ProfileCard>

                  {/* Change Password Card */}
                  <ProfileCard>
                    <CardContent>
                      <SectionTitle>Change Password</SectionTitle>
                      <InputField
                        label="Current Password"
                        type="password"
                        variant="outlined"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <InputField
                        label="New Password"
                        type="password"
                        variant="outlined"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <InputField
                        label="Confirm New Password"
                        type="password"
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <ActionButton onClick={handlePasswordChange} startIcon={<Lock />}>
                        Change Password
                      </ActionButton>
                    </CardContent>
                  </ProfileCard>

                  {/* Delete Account Card */}
                  <ProfileCard>
                    <CardContent>
                      <SectionTitle>Delete Account</SectionTitle>
                      <Typography variant="body2" color="textSecondary">
                        Deleting your account is permanent and cannot be undone.
                      </Typography>
                      <ActionButton
                        onClick={() => setOpenDeleteDialog(true)}
                        startIcon={<Delete />}
                        sx={{ marginTop: 2 }}
                      >
                        Delete Account
                      </ActionButton>
                    </CardContent>
                  </ProfileCard>
                </Grid>
              </Grid>

              {/* Snackbar for feedback */}
              <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
              >
                <Alert
                  onClose={() => setOpenSnackbar(false)}
                  severity="success"
                  sx={{ width: '100%' }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>

              {/* Delete Account Confirmation Dialog */}
              {openDeleteDialog && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '1000',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: 3,
                    }}
                  >
                    <Typography variant="h6" color="error">
                      Are you sure you want to delete your account?
                    </Typography>
                    <Box sx={{ marginTop: 2 }}>
                      <ActionButton
                        onClick={handleDeleteAccount}
                        startIcon={<Delete />}
                        sx={{ backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }}
                      >
                        Yes, Delete Account
                      </ActionButton>
                      <ActionButton
                        onClick={() => setOpenDeleteDialog(false)}
                        sx={{
                          marginLeft: 2,
                          backgroundColor: '#00796b',
                          '&:hover': { backgroundColor: '#004d40' },
                        }}
                      >
                        Cancel
                      </ActionButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>

            {/* Edit/Save Button */}
            <ActionButton
              startIcon={isEditing ? <Save /> : <Edit />}
              onClick={handleEditToggle}
            >
              {isEditing ? 'Save Changes' : 'Edit Profile'}
            </ActionButton>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
    <Footer/>
    </Box>
  );
}

export default UserProfile;
