import React from 'react'

function Jobs() {
  return (
    <div>
      Jobs
    </div>
  )
}

export default Jobs
