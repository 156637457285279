import React, { useEffect, useState } from 'react';

function TermsAndConditions() {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch('./demo.html')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text();
          })
          .then((text) => setHtmlContent(text))
          .catch((error) => console.error('Fetch error:', error));
      }, []);

  return (
    <div style={{ backgroundColor: 'white', padding: '20px', minHeight: '100vh', color: 'black' }}>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
}

export default TermsAndConditions;


