import React from 'react'
import Header1 from '../Header/Header1'

function Main() {
  return (
    <div>
      <Header1/>
main
    </div>
  )
}

export default Main
