import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, TextField, Box, Button, Menu, MenuItem, Avatar, Typography, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import UserProfile from '../UserProfile/UserProfile';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import axios from 'axios';
// import { AppBar, Toolbar, IconButton, TextField, Box, Menu, MenuItem, Avatar, Typography, Divider } from '@mui/material';
function Header2({ userId, onLogout, page_name, onSearch }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [isOnline, setIsOnline] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    let dropdownRef = useRef();

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchQuery.trim() !== '') {
            fetch(`http://127.0.0.1:8000/get_search_results/?page_name=${page_name}&search_input=${searchQuery}&user_id=${userId}`)
                .then(response => response.json())
                .then(data => {
                    onSearch(data);
                    setSearchQuery('');
                })
                .catch(error => console.error('Error searching:', error));
        }
    };

    const handleClear = () => setSearchQuery('');

    const toggleOnlineStatus = () => {
        const newStatus = !isOnline ? 'active' : 'inactive';
        setIsOnline((prevState) => !prevState);
        axios.post('http://127.0.0.1:8000/update_online_status/', { userId, status: newStatus })
            .then(response => console.log('Status updated successfully:', response.data))
            .catch(error => console.error('Error updating status:', error));
    };

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{
                    bgcolor: 'black',
                    color: 'white',
                    borderBottom: '1px solid #1E90FF',
                    zIndex: 1100
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to="/home">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                transition: 'transform 0.3s',
                                mr: 1,
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                }
                            }}
                        >
                            <img
                                src={Logo}
                                alt="Logo"
                                style={{ width: '120px', height: 'auto', maxWidth: '100%' }}
                            />
                        </Box>
                    </Link>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Link to={`/platform/${userId}`} style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" color="white">
                                Platform
                            </Typography>
                        </Link>

                        {/* <Link to="/about-us" style={{ textDecoration: 'none' }}>
                            <Typography variant="body1" color="white">
                                About us
                            </Typography>
                        </Link> */}


                        <IconButton onClick={handleMenuOpen} ref={dropdownRef} color="inherit">
                            <Avatar />
                        </IconButton>
                        <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    style: {
                                        width: 200,
                                    },
                                    sx: {
                                        bgcolor: '#0e0e0e', // Darker background
                                        color: 'white', // White text
                                        border: '1px solid #333',
                                        borderRadius: 3,
                                        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.8)',
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right', // Align dropdown to the right of the anchor
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right', // Ensure the dropdown opens from the right
                                  }}
                            >
                                {/* Profile Section */}
                                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, bgcolor: '#1a1a1a', borderRadius: '6px 6px 0 0' }}>
                                    <Avatar sx={{ bgcolor: '#1E90FF', width: 48, height: 48 }}>JD</Avatar>
                                    <Box>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#E1E1E1' }}>
                                            John Doe
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#AAA' }}>
                                            @johndoe
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ bgcolor: '#333' }} />

                                {/* Dropdown Options */}
                                <MenuItem
                                    component={Link}
                                    to={`/UserProfile/${userId}`}
                                    onClick={handleMenuClose}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        py: 1.5,
                                        '&:hover': { bgcolor: '#2a2a2a' },
                                    }}
                                >
                                    <PersonIcon sx={{ color: '#1E90FF' }} />
                                    <Typography>User Details</Typography>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/subscriptions"
                                    onClick={handleMenuClose}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        py: 1.5,
                                        '&:hover': { bgcolor: '#2a2a2a' },
                                    }}
                                >
                                    <PaymentIcon sx={{ color: '#1E90FF' }} />
                                    <Typography>Subscription</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        onLogout();
                                    }}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        py: 1.5,
                                        '&:hover': { bgcolor: '#2a2a2a' },
                                    }}
                                >
                                    <LogoutIcon sx={{ color: '#1E90FF' }} />
                                    <Typography>Logout</Typography>
                                </MenuItem>
                            </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header2;
