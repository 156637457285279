import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import { useState } from "react";
import { BrowserRouter,Routes,Route, Navigate } from "react-router-dom";
import { IdProvider } from "./Components/Home/IdContext.js";
import AboutUs from './Components/Footer/AboutUs.js';
import ContactUs from './Components/Footer/ContactUs.js';
import Careers from './Components/Footer/Careers/Careers.js';
import Internship from './Components/Footer/Careers/Internship.js';
import Jobs from './Components/Footer/Careers/Jobs.js';
import Faqs from './Components/Footer/Faqs.js';
import TermsAndConditions from './Components/Footer/TermsAndConditions.js';
import ComingSoon from './Components/Header/ComingSoon.js';
import AiContent from './Components/Home/AiContent.js';
import Recommendations from './Components/Diwakar_dev/Recommendations/Recommendations.js';
import UserProfile from './Components/UserProfile/UserProfile.js';
import Documentation from './Components/Diwakar_dev/Documentation.js';
import Demo from './Components/Diwakar_dev/Demo.js';
import DataAnalytics from './Components/Diwakar_dev/DataAnalytics.js';
import Login from './Components/Login/Login.jsx';
import SignUp from "./Components/SignUp/SignUp";
import Main from "./Components/Main/Main.jsx";
import PostSection from './Components/Post/PostSection.js';
import CreatePost from './Components/Post/CreatePost.js';
import Platform from './Components/Platform/Platform.js';
import UserDetails from './Components/Main/Userprofile/UserDetails.jsx';
import Posted from './Components/Post/Posted.js';
import FullSection from './Components/Post/FullSection.js';
import PostTabDetails from './Components/Post/PostTabDetails.js';
import MyPosts from './Components/Post/MyPosts.js';
import CreateSubscriptions from './Components/Admin/CreateSubscriptions.js';
import CreateSections from './Components/Admin/CreateSections.js';
import AddSubSections from './Components/Admin/AddSections.js';
import Subscriptions from './Components/Platform/Subscriptions.js';
// import { Category } from '@mui/icons-material';
import Categories from './Components/Platform/Catergories.js';

function App() {

  const [loggedIn,setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleLogin = (userId) => {
    setLoggedIn(true)
    setUserId(userId)
  }

  const handleLogout = () => {
    setLoggedIn(false);
    setUserId(null);
  };


  return (
<BrowserRouter>
    <IdProvider>
      <Routes>
        {/* <Route path='/login' element={loggedIn ? <Navigate to = '/home'/> : <Login onLogin={handleLogin} /> }></Route> */}
        <Route path='/login' element={<ComingSoon/>}></Route>
        {/* <Route path='/home' element={<Home loggedIn={loggedIn} userId={userId} onLogout={handleLogout} />}></Route> */}
        <Route path='/home' element={<Home/>}></Route>
        <Route path='/about-us' element={<AboutUs/>}/>
        {/* <Route path='/contact-us' element={<ContactUs/>}/> */}
        {/* <Route path='/careers' element={<Careers/>}/> */}
        {/* <Route path='/careers-internship' element={<Internship/>}/> */}
        <Route path='/careers-jobs' element={<Jobs/>}/>
        <Route path='/faqs' element={<Faqs/>}/>
        <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
        {/* <Route path='/signup' element={<SignUp/>}/> */}
        <Route path='/main' element={<Main/>}></Route>
        {/* <Route path='/post/:id' element={<CreatePost userId={userId} onLogout={handleLogout}/>} />
        <Route path='/post-section' element={<PostSection userId={userId} onLogout={handleLogout}/>} />
        <Route path='/platform/:id' element={<Platform userId={userId} onLogout={handleLogout}/>}></Route>
        <Route path='/full-section/:id' element={<FullSection userId={userId} onLogout={handleLogout}/>} />
        <Route path='/post-tab-details/:id' element={<PostTabDetails userId={userId} onLogout={handleLogout}/>} /> */}
        {/* <Route path='/subscriptions' element={<Subscriptions/>}/>
        <Route path='/create-subscriptions' element={<CreateSubscriptions/>}/>
        <Route path='/create-sections' element={<CreateSections/>}/>
        <Route path='/add-subsections' element={<AddSubSections/>}/> */}
    
        
        {/* <Route path='/ai-content' element={<AiContent/>}/>
        <Route path='/category' element={<Categories/>}/>
        <Route path='/UserProfile/:id' element={<UserProfile/>}/>
        <Route path='/Documentation' element={<Documentation/>}/>
        <Route path='/Demo' element={<Demo/>}/>
        <Route path='/DataAnalytics' element={<DataAnalytics/>}/> */}

        <Route path="*" element={<Navigate to="/home" />} /> */
       </Routes>

    </IdProvider>

    </BrowserRouter>
  );
}

export default App;
